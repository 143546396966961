import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'

Vue.use(Router)

export default new Router({
  mode: "history",
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        title: 'Lyže, snowboardy, výstroj, bicykle a kolobežky | rent - service - shop | Ski * Niňaj',
        metaTags: [
          {
            name: 'description',
            content: 'Požičovňa, servis a predaj širokého sortimentu lyží, snowboardov a výstroje v zime. Elektrobicykle, horské bicykle a kolobežky v lete.'
          },
          {
            property: 'og:description',
            content: 'Požičovňa, servis a predaj širokého sortimentu lyží, snowboardov a výstroje v zime. Elektrobicykle, horské bicykle a kolobežky v lete.'
          }
        ]
      }
    },
    {
      path: '/pozicovna-lyziarskej-a-snowboardovej-vystroje',
      name: 'pozicovna',
      component: () => import(/* webpackChunkName: "pozicovna" */ './views/Pozicovna.vue'),
      meta: {
        title: 'Požičovňa lyží ⛷ a snowboardov | predaj a servis | Ski * Niňaj',
        metaTags: [
          {
            name: 'description',
            content: 'Požičovňa lyží ⛷, snowboardov 🏂, kompletnej výstroje pre dokonalý zážitok z lyžovania. Zabezpečujeme i servis, alebo predaj.'
          },
          {
            property: 'og:description',
            content: 'Požičovňa lyží ⛷, snowboardov 🏂, kompletnej výstroje pre dokonalý zážitok z lyžovania. Zabezpečujeme i servis, alebo predaj.'
          }
        ]
      }
    },
    {
      path: '/servis-lyzi-snowboardov',
      name: 'skiservis',
      component: () => import(/* webpackChunkName: "skiservis" */ './views/Skiservis.vue'),
      meta: {
        title: 'Servis pre lyže a snowboard | rent - service - shop | Ski * Niňaj',
        metaTags: [
          {
            name: 'description',
            content: 'Perfektný sklz lyží, lepšie držanie hrán, bezpečnejšia jazda. Veľký servis - celková oprava, malý servis - brúsenie, tunning, voskovanie a leštenie.'
          },
          {
            property: 'og:description',
            content: 'Perfektný sklz lyží, lepšie držanie hrán, bezpečnejšia jazda. Veľký servis - celková oprava, malý servis - brúsenie, tunning, voskovanie a leštenie.'
          }
        ]
      }
    },
    {
      path: '/predaj-lyzi-snowboardov-lyziarok',
      name: 'predaj',
      component: () => import(/* webpackChunkName: "predaj" */ './views/Predaj.vue'),
      meta: {
        title: 'Na predaj lyže, snowboard, skialp, bežky a ďalšia výstroj | rent - service - shop | Ski * Niňaj',
        metaTags: [
          {
            name: 'description',
            content: 'Predaj použitého lyžiarskeho, snowboardového vybavenia, väčšinou 1 rok používaného vo výbornom stave za super ceny, všetky značky a veľkosti.'
          },
          {
            property: 'og:description',
            content: 'Predaj použitého lyžiarskeho, snowboardového vybavenia, väčšinou 1 rok používaného vo výbornom stave za super ceny, všetky značky a veľkosti.'
          }
        ]
      }
    },
    {
      path: '/detske-vystroje',
      name: 'deti',
      component: () => import(/* webpackChunkName: "deti" */ './views/Deti.vue'),
      meta: {
        title: 'Detské lyžiarske a snowboardové výstroje | rent - service - shop | Ski * Niňaj',
        metaTags: [
          {
            name: 'description',
            content: 'Ponúkame Vám detskú lyžiarsku výstroj na celú sezónu za skvelú cenu. Využite našu ponuku a doprajte zážitok zo snehu Vašim najmenším.'
          },
          {
            property: 'og:description',
            content: 'Ponúkame Vám detskú lyžiarsku výstroj na celú sezónu za skvelú cenu. Využite našu ponuku a doprajte zážitok zo snehu Vašim najmenším.'
          }
        ]
      }
    },
    {
      path: '/celosezonne-pozicanie',
      name: 'celosezonne',
      component: () => import(/* webpackChunkName: "celosezonne" */ './views/Celosezonne.vue'),
      meta: {
        title: 'Celosezónne požičanie výstroje | rent - service - shop | Ski * Niňaj',
        metaTags: [
          {
            name: 'description',
            content: 'Celosezónne požičanie výstroje'
          },
          {
            property: 'og:description',
            content: 'Celosezónne požičanie výstroje'
          }
        ]
      }
    },
    {
      path: '/pozicovna-bicyklov',
      name: 'pozicovna-bicyklov',
      component: () => import(/* webpackChunkName: "pozicovna-bicyklov" */ './views/PozicovnaBicyklov.vue'),
      meta: {
        title: 'Požičovňa bicyklov 🚴 | predaj a servis | Ski * Niňaj',
        metaTags: [
          {
            name: 'description',
            content: 'Požičovňa bicyklov 🚴 - vychutnajte si krásy prírody z bicykla a majte dobrý pocit z toho, že ste urobili niečo pre seba.'
          },
          {
            property: 'og:description',
            content: 'Požičovňa bicyklov 🚴 - vychutnajte si krásy prírody z bicykla a majte dobrý pocit z toho, že ste urobili niečo pre seba.'
          }
        ]
      }
    },
    {
      path: '/pozicovna-elektrobicyklov',
      name: 'pozicovna-elektrobicyklov',
      component: () => import(/* webpackChunkName: "pozicovna-elektrobicyklov" */ './views/PozicovnaElektrobicyklov.vue'),
      meta: {
        title: 'Požičovňa elektrobicyklov 🚵 | predaj a servis | Ski * Niňaj',
        metaTags: [
          {
            name: 'description',
            content: 'Požičovňa elektrobicyklov 🚵 - zájdite ďalej, rýchlejšie a pohodlnejšie i tam, kde ste predtým nemohli a vyskúšajte niečo nové.'
          },
          {
            property: 'og:description',
            content: 'Požičovňa elektrobicyklov 🚵 - zájdite ďalej, rýchlejšie a pohodlnejšie i tam, kde ste predtým nemohli a vyskúšajte niečo nové.'
          }
        ]
      }
    },
    {
      path: '/kontakt',
      name: 'kontakt',
      component: () => import(/* webpackChunkName: "kontakt" */ './views/Kontakt.vue')
    },
    {
      path: '/kontakt/:n',
      name: 'kontakt-detail',
      component: () => import(/* webpackChunkName: "kontakt" */ './views/Kontakt.vue')
    },
    { 
      path: '/stranka-nenajdena',
      name: 'stranka-nenajdena',
      component: () => import(/* webpackChunkName: "not-found" */ './views/Page404.vue'),
      meta: {
        title: '404 Page Not Found'
      }
    },
    { 
      path: '/page-404',
      name: 'page-404',
      component: () => import(/* webpackChunkName: "not-found" */ './views/Page404.vue'),
      meta: {
        title: '404 Page Not Found'
      }
    },
    { 
      path: '*',
      name: 'not-found',
      beforeEnter: () => {
        window.location.href = "/page-404";
      }
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})
