<script>
import AppFooter from '@/components/AppFooter'

export default {
  name: 'home',
  components: {
    AppFooter
  },
  methods: {
    scrollTo(ref) {
      const element = this.$refs[ref].$el
      window.scrollTo(0, element.offsetTop - 120);
    }
  }
};
</script>

<template>
  <div class="home-view" :class="{ 'leto': $isSummer }">
    <article>
      <div class="boxes" v-if="$isSummer">
        <div class="box" style="box-shadow: 0 0 0 15px rgba(244, 67, 54, 0.35)">
          <img alt="Akcia" src="@/assets/icons/discount.svg" width="65" height="65">
          <h3>Detské bicykle a kolobežky</h3>
          <p>Pre Vaše deti máme pripravenú akciovú zľavu</p>
          <p class="price">30 %</p>
        </div>
        <div class="box" style="box-shadow: 0 0 0 15px rgba(92, 167, 198, 0.35)">
          <img alt="Prvá pomoc" src="@/assets/icons/guide.svg" width="65" height="65">
          <h3>Sprievodca na cyklotúry</h3>
          <p>Sprievodca Vám odporučí vhodnú trasu podľa náročnosti a Vašich požiadaviek, finálna cena závisí od počtu osôb</p>
          <p class="price">od 20 € / deň</p>
        </div>
        <div class="box" style="box-shadow: 0 0 0 15px rgba(1, 231, 158, 0.4)">
          <img alt="Výmena výstroje" src="@/assets/icons/electric-bike.svg" width="65" height="65">
          <h3>Elektrobicykle za najlepšie ceny</h3>
          <p>Vyberte si z našej veľkej ponuky elektrobicyklov, bicyklov a kolobežiek. Ponúkame požičanie e-bajku už od</p>
          <p class="price">20 €</p>
        </div>
      </div>
      <div class="boxes" v-else>
        <div class="box" style="box-shadow: 0 0 0 15px rgba(244, 67, 54, 0.35)">
          <img alt="Akcia" src="@/assets/icons/discount.svg" width="65" height="65">
          <h3>Detská výstroj na celú sezónu</h3>
          <p>Ponúkame Vám detskú lyžiarsku výstroj na celú zimnú sezónu za akciovú cenu</p>
          <p class="price">60 €</p>
        </div>
        <div class="box" style="box-shadow: 0 0 0 15px rgba(92, 167, 198, 0.35)">
          <img alt="Prvá pomoc" src="@/assets/icons/medical-kit.svg" width="65" height="65">
          <h3>Prvá pomoc pre Vaše lyže po zime</h3>
          <p>Prídťe si k nám opraviť, nabrúsiť alebo navoskovať Vaše lyže a snowboardy. Posezónnu opravu ponúkame za výhodnú cenu</p>
          <p class="price">30 €</p>
        </div>
        <div class="box" style="box-shadow: 0 0 0 15px rgba(127, 221, 54, 0.4)">
          <img alt="Výmena výstroje" src="@/assets/icons/change-equipment.svg" width="65" height="65">
          <h3>Výstroj za najlepšie ceny</h3>
          <p>Vyberte si z našej čerstvej ponuky jazdených lyží, snowboardov a lyžiarok. Lyže spolu s lyžiarkami už od</p>
          <p class="price">90 €</p>
        </div>
      </div>
      
      <template v-if="$isSummer">
        <div class="full-box full-box-kurzy bg-kolobezky">
          <div class="full-box-content">
            <h3 style="color: #72904f">Kolobežky v Jánskej doline</h3>
            <p>Zažite adrenalínové dobrodružstvo pri jazde kolobežkou od Stanišovskej jaskyne k Hotelu Ďumbier v Jánskej doline:</p>
            <p><font-awesome-icon icon="check-circle" /> máme otvorené každý deň v júli a auguste, od 10:00 do 17:00</p>
            <p><font-awesome-icon icon="check-circle" /> cena <b>3 &euro; / dieťa</b> a <b>5 &euro; / dospelý</b></p>
          </div>
        </div>

        <div class="full-box content-right full-box-mobil-rent bg-mobil-rent-back-leto">
          <div class="full-box-content">
            <Imgix alt="Mobil rent" src="homepage/mobil-rent-leto.png" class="mobil-rent" loading="lazy" width="482" height="449" />
            <h3 style="color: #a6ea00">Dovoz elektrobicyklov, bicyklov a kolobežiek priamo k Vám!</h3>
            <p>Pre väčší komfort Vám výbavu radi dovezieme, min. <b>4 ks</b> za cenu <b>12 &euro; / ks</b>.</p>
            <p><img alt="Akciová cena" src="@/assets/icons/phone-smile.svg" width="30" height="30"> Kontaktujte MOBIL RENT - pojazdnú požičovňu - na čísle <a href="tel:+4210905357133" title="Zavolaje">0905 357 133</a>.</p>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="full-box full-box-kurzy bg-kurzy-back">
          <div class="full-box-content">
            <h3 style="color: #f71735">Lyžiarske kurzy</h3>
            <p>Požičiame Vám výstroj na lyžiarsky kurz - za 6 dní ponúkame výstroj v akciových cenách:</p>
            <p><img alt="Akciová cena" src="@/assets/icons/price-tag.svg"> <b>30 €</b> pre žiakov MŠ a ZŠ</p>
            <p><img alt="Akciová cena" src="@/assets/icons/price-tag.svg"> <b>35 €</b> pre žiakov stredných škôl a gymnázií</p>
          </div>
        </div>

        <div class="full-box content-right full-box-mobil-rent bg-mobil-rent-back">
          <div class="full-box-content">
            <Imgix alt="Mobil rent" src="homepage/mobil-rent.png" class="mobil-rent" loading="lazy" width="478" height="426" />
            <h3 style="color: #a6ea00">Dovoz lyžiarskej výstroje priamo k Vám!</h3>
            <p>Pre väčší komfort Vám výbavu radi dovezieme.</p>
            <p><img alt="Mobilný kontakt" src="@/assets/icons/phone-smile.svg"> Kontaktujte MOBIL RENT - pojazdnú požičovňu - na čísle <a href="tel:+4210905357133" title="Zavolaje">0905 357 133</a>.</p>
          </div>
        </div>

        <div class="full-box full-box-night-service bg-night-service-back">
          <div class="full-box-content full-box-content-dark">
            <h3 style="color: #ffee56">Nočný servis</h3>
            <p>Nechajte Vaše lyže alebo snowboard na recepcii (vyzdvihneme ich v čase 17:00 - 20:00), ráno na Vás počkajú pripravené v perfektnom stave (7:30 - 8:30).</p>
            <p>Kontaktujte NIGHT SERVIS - na čísle <a href="tel:+4210905357133" title="Zavolaje">0905 357 133</a>.</p>
          </div>
        </div>
      </template>
    </article>

    <app-footer>
      <b>Naša ponuka:</b>
        <ul v-if="$isSummer">
          <li><router-link :to="{ name: 'pozicovna-elektrobicyklov' }">Elektrobicykle</router-link></li>
          <li><router-link :to="{ name: 'pozicovna-bicyklov' }">Bicykle</router-link></li>
          <li><a href="https://www.kolobezkyliptov.sk/" target="_blank">Kolobežky</a></li>
          <li><router-link :to="{ name: 'predaj' }">Predaj lyží, snowboardov, lyžiarok a doplnkov</router-link></li>
          <li><router-link :to="{ name: 'skiservis' }">Servis lyží a snowboardov</router-link></li>
          <li><router-link :to="{ name: 'pozicovna' }">Požičovňa lyžiarskej, snowboardovej, skialpovej výstroje</router-link></li>
          <li><router-link :to="{ name: 'deti' }">Detské výstroje</router-link></li>
        </ul>
        <ul v-else>
          <li><router-link :to="{ name: 'pozicovna' }">Požičovňa lyžiarskej, snowboardovej, skialpovej výstroje</router-link></li>
          <li><router-link :to="{ name: 'skiservis' }">Servis lyží a snowboardov</router-link></li>
          <li><router-link :to="{ name: 'predaj' }">Predaj lyží, snowboardov, lyžiarok a doplnkov</router-link></li>
          <li><router-link :to="{ name: 'deti' }">Detské výstroje</router-link></li>
          <li><router-link :to="{ name: 'celosezonne' }">Celosezónne požičanie</router-link></li>
          <li><router-link :to="{ name: 'pozicovna-elektrobicyklov' }">Elektrobicykle</router-link> a <router-link :to="{ name: 'pozicovna-bicyklov' }">bicykle</router-link></li>
          <li><a href="https://www.kolobezkyliptov.sk/" target="_blank">Kolobežky</a></li>
        </ul>
    </app-footer>
  </div>
</template>

<style lang="scss" scoped>
  .bg-kolobezky {
    background-image: url(https://skiservis.imgix.net/homepage/kolobezky.jpg?auto=format&dpr=1);
    background-color: #eaf7b1;

    @media only screen and (min-device-pixel-ratio: 2), (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
        background-image: url(https://skiservis.imgix.net/homepage/kolobezky.jpg?auto=format&dpr=2);
    }

    @media only screen and (min-device-pixel-ratio: 3), (-webkit-min-device-pixel-ratio: 3), (min-resolution: 3dppx) {
        background-image: url(https://skiservis.imgix.net/homepage/kolobezky.jpg?auto=format&dpr=3);
    }
  }

  .bg-mobil-rent-back-leto {
    background-image: url(https://skiservis.imgix.net/homepage/mobil-rent-back-leto.jpg?auto=format);
    background-color: #fff;

    @media only screen and (min-device-pixel-ratio: 2), (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
        background-image: url(https://skiservis.imgix.net/homepage/mobil-rent-back-leto.jpg?auto=format&dpr=2);
    }

    @media only screen and (min-device-pixel-ratio: 3), (-webkit-min-device-pixel-ratio: 3), (min-resolution: 3dppx) {
        background-image: url(https://skiservis.imgix.net/homepage/mobil-rent-back-leto.jpg?auto=format&dpr=3);
    }
  }

  .bg-kurzy-back {
    background-image: url(https://skiservis.imgix.net/homepage/kurzy-back.jpg?auto=format&dpr=1);
    background-color: #dbf4f8;

    @media only screen and (min-device-pixel-ratio: 2), (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
        background-image: url(https://skiservis.imgix.net/homepage/kurzy-back.jpg?auto=format&dpr=2);
    }

    @media only screen and (min-device-pixel-ratio: 3), (-webkit-min-device-pixel-ratio: 3), (min-resolution: 3dppx) {
        background-image: url(https://skiservis.imgix.net/homepage/kurzy-back.jpg?auto=format&dpr=3);
    }
  }

  .bg-mobil-rent-back {
    background-image: url(https://skiservis.imgix.net/homepage/mobil-rent-back.jpg?auto=format);
    background-color: #fff;

    @media only screen and (min-device-pixel-ratio: 2), (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
        background-image: url(https://skiservis.imgix.net/homepage/mobil-rent-back.jpg?auto=format&dpr=2);
    }

    @media only screen and (min-device-pixel-ratio: 3), (-webkit-min-device-pixel-ratio: 3), (min-resolution: 3dppx) {
        background-image: url(https://skiservis.imgix.net/homepage/mobil-rent-back.jpg?auto=format&dpr=3);
    }
  }

  .boxes {
    justify-content: center;

    @media (max-width: 1000px) {
      flex-direction: column;
    }

    @media (max-width: 640px) {
      padding: .5rem 0;
    }

    .box {
      max-width: 400px;
      padding: .5rem 1rem;
      margin: 4% 2%;
      overflow: hidden;
      border: 3px solid #000;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      @media (max-width: 1000px) {
        max-width: none;
        width: 90%;
        margin: 5%;
      }

      @media (max-width: 640px) {
        width: calc(100% - 4rem);
        margin: 1.5rem 2rem;
      }

      img {
        width: 65px;
        vertical-align: middle;
        margin: 1rem;
      }

      h3 {
        font-size: 21px;
        padding: 0;
        margin: 0;
        font-weight: 600;
      }

      .button-action {
        font-weight: normal;
        font-size: 1.1rem;
        border-radius: 2rem;
        padding: 0.5rem 1.5rem;
      }

      p {
        padding: .5rem 1rem;
        margin: 0;
      }

      .price {
        font-size: 28px;
        font-weight: 600;
      }
    }
  }

  .full-box {
    .full-box-content {
      @media (min-width: 1024px) {
        max-width: 50%;
      }

      .mobil-rent {
        @media (min-width: 1024px) {
          position: absolute;
          left: 15px;
          top: -30px;
          z-index: 1;
        }

        @media (min-width: 1200px) {
          left: 95px;
        }

        @media (max-width: 1023px) {
          width: 35%;
          margin: 5% 5% 0 0;
          float: left;
        }

        @media (max-width: 640px) {
          float: right;
          margin: 5% 0 0 5%;
        }
      }
    }

    p {
      line-height: 2;
    }

    h3 {
      font-weight: 200;
      font-size: 30px;
      margin: 1rem 0;
    }

    a {
      color: #0968aa;
      background: #fff;
      border-radius: 5px;
      padding: 0.1rem;

      .leto > & {
        color: #12941d;
      }
    }

    &.full-box-kurzy {
      p > img {
        vertical-align: sub;
        margin-right: 10px;
      }
    }

    &.full-box-mobil-rent {
      @media (max-width: 1024px) and (min-width: 801px) {
        background-position: -20rem;
        align-items: initial;
      }

      @media (max-width: 800px) {
        align-items: initial;
        background-position: 250% 0;

        p {
          margin-left: 40%;
        }
      }

      @media (max-width: 640px) {
        p {
          margin-left: 0;
        }
      }

      @media (max-width: 500px) {
        .full-box-content {
          padding: 0;
        }
      }

      p > img {
        vertical-align: top;
        margin-right: 2px;
      }
    }

    &.full-box-night-service {
      p {
        color: #fff;
      }
    }
  }
</style>
