import Vue from 'vue'
import './plugins/fontawesome'
import App from './App.vue'
import router from './router'
import SweetModal from 'sweet-modal-vue/src/plugin.js'
import { Icon }  from 'leaflet'
import 'leaflet/dist/leaflet.css'
Vue.use(SweetModal)

import "@/assets/global.scss"

// this part resolve an issue where the markers would not appear
delete Icon.Default.prototype._getIconUrl

Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

Vue.config.productionTip = false

const currentDate = new Date()
const isSummer = currentDate.getMonth() > 2 && currentDate.getMonth() < 9
Vue.prototype.$isSummer = isSummer

const winterColor = "#1a5788"
const summerColor = "#72a94e"
const themeColor = isSummer ? summerColor : winterColor

Vue.filter('euro', function (value) {
  if (!value)
    return ''
  const floatVal = parseFloat(value)
  if (!floatVal)
    return value
  return `${floatVal} €`
})

Vue.component('Imgix', {
  functional: true,
  props: ['src'],
  render: function (createElement, context) {
    let src = "";
    if (context.props.src.toString().includes("?")) {
      src = `${context.props.src}&`
    } else {
      src = `${context.props.src}?`
    }
    return createElement(
      'img', {
        ...context.data,
        attrs: {
          ...context.data.attrs,
          src: `https://skiservis.imgix.net/${src}auto=format`,
          srcset: `https://skiservis.imgix.net/${src}auto=format&dpr=1 1x,
                   https://skiservis.imgix.net/${src}auto=format&dpr=2 2x, 
                   https://skiservis.imgix.net/${src}auto=format&dpr=3 3x`
        }
      }
    )
  }
})

Vue.component('my-functional-button', {
  functional: true,
  render: function (createElement, context) {
    // Transparently pass any attributes, event listeners, children, etc.
    return createElement('button', context.data, context.children)
  }
})

const customRouter = router;
router.beforeEach((to, from, next) => {
  router.app.mobileNavVisible = false;
  //Vue.prototype.$mobileNavVisible = false;
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
});

new Vue({
  data: {
    mobileNavVisible: false
  },
  router: customRouter,
  created: () => {
    document.querySelector('meta[name="theme-color"]').setAttribute('content',  themeColor);
    document.querySelector('meta[name="msapplication-TileColor"]').setAttribute('content',  themeColor);
  },
  render: h => h(App)
}).$mount('#app')
