import Vue from 'vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faClock } from '@fortawesome/free-regular-svg-icons'
import { faGlobe, faEnvelope, faPhoneAlt, faMapMarkerAlt, faCheck, faCheckCircle, faComment, faBars} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faClock)
library.add(faGlobe)
library.add(faEnvelope)
library.add(faPhoneAlt)
library.add(faMapMarkerAlt)
library.add(faCheck)
library.add(faCheckCircle)
library.add(faComment)
library.add(faBars)

Vue.component('font-awesome-icon', FontAwesomeIcon)
